<template>
    <div class="login">
        123
    </div>
</template>


<script>
export default {
    name:'Login',
    created(){
        this.$emit('header',false)
    },
    methods:{

    }
}
</script>

<style lang="scss">
.login {
    height: 2000px;
    width: 100%;
    background: red;
}
</style>